import React from "react"

import Form from "react-bootstrap/Form"
import Col from "react-bootstrap/Col"

const Adres = ({ values, handleChange, errors, touched }) => {
  return (
    <div className="flex flex-col mx-auto items-center form-group col-md-6">
      <div className="text-left w-full">
        <Form.Row>
          <Form.Group as={Col} md="6" controlId="validationFormik01">
            <Form.Label>
              Straat <span className="text-red-700">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              name={`straat`}
              value={values.straat}
              onChange={handleChange}
              placeholder="Straat"
              isValid={touched.straat && !errors.straat}
              isInvalid={touched.straat && !!errors.straat}
            />
            <Form.Control.Feedback>Perfect!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              {"Straat " + errors.straat}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="3" controlId="validationFormik02">
            <Form.Label>
              Nummer <span className="text-red-700">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="nummer"
              placeholder="Nummer"
              value={values.nummer}
              onChange={handleChange}
              isValid={touched.nummer && !errors.nummer}
              isInvalid={touched.nummer && !!errors.nummer}
            />

            <Form.Control.Feedback>Perfect!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              {"Nummer " + errors.nummer}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="3" controlId="validationFormik03">
            <Form.Label>Bus</Form.Label>
            <Form.Control
              type="text"
              name="bus"
              value={values.bus}
              onChange={handleChange}
              placeholder="Bus"
              isValid={touched.bus && !errors.bus}
              isInvalid={touched.bus && !!errors.bus}
            />

            <Form.Control.Feedback>Perfect!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              {"Bus " + errors.bus}
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} md="6" controlId="validationFormik04">
            <Form.Label>
              Postcode <span className="text-red-700">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="postcode"
              value={values.postcode}
              onChange={handleChange}
              placeholder="Postcode"
              isValid={touched.postcode && !errors.postcode}
              isInvalid={touched.postcode && !!errors.postcode}
            />
            <Form.Control.Feedback>Perfect!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              {"Postcode " + errors.postcode}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="validationFormik05">
            <Form.Label>
              Plaats <span className="text-red-700">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="stad"
              placeholder="Stad/Gemeente"
              value={values.stad}
              onChange={handleChange}
              isValid={touched.stad && !errors.stad}
              isInvalid={touched.stad && !!errors.stad}
            />

            <Form.Control.Feedback>Perfect!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              {"Plaats " + errors.stad}
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
      </div>
    </div>
  )
}

export default Adres
