import React from "react"
import Slider from "react-animated-slider"
import "react-animated-slider/build/horizontal.css"
import styled from "styled-components"

import { graphql, useStaticQuery } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Image from "gatsby-image"

const StyledImg = styled(Image)`
  background-position: 0% center;
`
const StyledDiv = styled.div`
  p {
    padding-right: 10rem;
    font-family: "Lato, serif";
    color: "#363d45";
  }
  @media screen and (max-width: 769px) {
    p {
      color: #363d45;
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
`
const OuterDiv = styled.div`
  text-align: left;
  padding-left: 9.5rem;
  p {
    padding-left: 0;
  }
  @media screen and (max-width: 769px) {
    text-align: center;
    padding-left: 0;
    p {
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }
`

export default function Review() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulCustomerReviews {
        edges {
          node {
            title
            naam
            foto {
              fluid(quality: 90, maxWidth: 500, maxHeight: 500) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            description {
              json
            }
          }
        }
      }
    }
  `)
  const reviews = data.allContentfulCustomerReviews.edges
  return (
    <div className="flex w-full mx-auto flex-column text-left items-center justify-center my-auto text-center">
      <h2 className="text-brand-black m-4 font-heading text-xl md:text-4xl px-12 md:px-0">
        We spreken niet voor onszelf
      </h2>
      <Slider infinite="true" autoplay={4000}>
        {reviews.map((review, index) => (
          <div
            key={index}
            className="flex flex-col md:flex-row justify-center items-center"
          >
            <div className="flex flex-col md:ml-20">
              <StyledImg
                fluid={review.node.foto.fluid}
                alt={review.node.title}
                className="rounded-full h-20 w-20 md:h-32 md:w-32 flex items-center justify-center"
              />
              <p className="p-0 mt-2 mx-auto text-sm md:text-base font-body text-brand-dark-gray">
                {review.node.naam}
              </p>
            </div>
            <OuterDiv className="flex flex-col">
              <h2 className="font-heading text-brand-black mb-4 text-base md:text-2xl">
                {review.node.title}
              </h2>
              <StyledDiv>
                {documentToReactComponents(review.node.description.json)}
              </StyledDiv>
            </OuterDiv>
          </div>
        ))}
      </Slider>
    </div>
  )
}
