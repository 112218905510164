import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Image from "gatsby-image"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { faLinkedin } from "@fortawesome/free-brands-svg-icons"

const BlogDiv = styled.div`
  p {
    font-family: "Lato, serif";
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 0.9rem;
    color: #363d45;
    @media screen and (max-width: 900px) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
  h1 {
    @media screen and (max-width: 900px) {
      font-size: 1.5rem;
    }
  }
`
const Team = () => {
  const data = useStaticQuery(graphql`
    {
      team: allContentfulTeam(sort: { fields: volgorde, order: ASC }) {
        nodes {
          naam
          linkedin
          beschrijving {
            json
          }
          foto {
            fluid(quality: 90, maxWidth: 700, maxHeight: 700) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  `)

  return (
    <div className="flex flex-col text-center w-10/12 mb-8">
      <h2 className="text-brand-black m-4 font-heading text-xl md:text-4xl">
        Team
      </h2>
      <div className="flex flex-col lg:flex-row mt-4 justify-center">
        {data.team.nodes.map(member => {
          return (
            <div
              className="mx-auto mb-4 lg:mx-32 text-center w-10/12 lg:w-1/5"
              key={member.naam}
            >
              <div className="image-container relative w-2/3 mx-auto">
                <Image
                  fluid={member.foto.fluid}
                  alt={member.naam}
                  className="w-full"
                />
                <div className="image-overlay flex flex-row items-center justify-center">
                  <a
                    href={member.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:text-brand-green"
                  >
                    <FontAwesomeIcon
                      style={{ width: "2rem", height: "auto" }}
                      icon={faLinkedin}
                      className="px-1 hover:text-brand-green"
                      role="button"
                      aria-label="LinkedIn"
                    />
                  </a>
                </div>
              </div>
              <p className="mt-4 mb-2 text-brand-black w-full font-heading px-4 mx-auto">
                {member.naam}
              </p>
              <BlogDiv className="w-full text-left text-brand-dark-gray font-body">
                {documentToReactComponents(member.beschrijving.json)}
              </BlogDiv>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Team
