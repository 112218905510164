import React, { useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Form from "../components/MultiForm/MultiFormAdvocaat"
import Email from "../components/forms/Email"
import PreviousEncounter from "../components/PreviousEncounter/PreviousEncounter"

const Ingebrekestelling = () => {
  const [showForm, setShowForm] = useState(false)
  const [showEmail, setShowEmail] = useState(false)
  const [showButtons, setShowButtons] = useState(true)
  const handleShowForm = () => {
    setShowForm(true)
  }
  const handleShowEmail = () => {
    setShowEmail(true)
  }

  return (
    <Layout>
      <SEO
        title="Formulier ingebrekestelling door onze advocaten"
        keywords={["Ingebrekestelling", "advocaten", "formulier"]}
      />
      <PreviousEncounter
        handleShowForm={handleShowForm}
        handleShowEmail={handleShowEmail}
        showButtons={showButtons}
        setShowButtons={setShowButtons}
      />
      <div className="mb-12">
        {showForm ? (
          <Form
            setShowForm={setShowForm}
            showButtons={showButtons}
            setShowButtons={setShowButtons}
          />
        ) : null}
        {showEmail ? (
          <Email
            setShowEmail={setShowEmail}
            showButtons={showButtons}
            setShowButtons={setShowButtons}
          />
        ) : null}
      </div>
    </Layout>
  )
}

export default Ingebrekestelling
