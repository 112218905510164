import React from "react"
import errorImg from "../../images/computer.svg"

const ErrorPage = ({ title, text }) => {
  return (
    <div className="mt-24 flex flex-col md:flex-row items-center justify-center">
      <img
        src={errorImg}
        alt="error icoon"
        className="h-64 md:h-auto md:w-svg mb-4 md:mt-4"
      ></img>
      <div className="flex flex-col items-center justify-center relative">
        <h2 className="font-heading text-brand-black text-center">{title}</h2>
        <p className="font-body text-brand-dark-gray px-4 md:mb-0">{text}</p>
      </div>
    </div>
  )
}
export default ErrorPage
