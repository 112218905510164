export const particulier = [
  {
    veld: "adres",
    label: "adres",
    placeholder: "Straat + Nummer, Postcode Gemeente",
  },
  { veld: "email", label: "e-mail", placeholder: "E-mail adres" },
  {
    veld: "telefoonnummer",
    label: "telefoonnummer",
    placeholder: "Tel. nummer",
  },
  {
    veld: "adreshuurpand",
    label: "adres huurpand",
    placeholder: "Straat + Nummer, Postcode Gemeente",
  },
  { veld: "huurprijs", label: "huurprijs", placeholder: "Huurprijs" },
  {
    veld: "huurachterstal",
    label: "totaal bedrag huurachterstal",
    placeholder: "Totaal huurachterstal",
  },
  {
    veld: "startdatum",
    label: "startdatum huurovereenkomst",
    placeholder: "DD/MM/JJJJ",
  },
  {
    veld: "einddatum",
    label: "einddatum huurovereenkomst",
    placeholder: "DD/MM/JJJJ",
  },
]

export const vennootschap = [
  {
    veld: "naamvennootschap",
    label: "naam vennootschap (inclusief rechtsvorm)",
    placeholder: "bv. BVBA Achterstallige huur",
  },

  {
    veld: "adres",
    label: "maatschappelijke zetel (adres)",
    placeholder: "Straat + Nummer, Postcode Gemeente",
  },
  {
    veld: "btwnummer",
    label: "btw-nummer",
    placeholder: "BEXXXX.XXX.XXX",
  },
  { veld: "email", label: "e-mail", placeholder: "E-mail adres" },

  {
    veld: "telefoonnummer",
    label: "telefoonnummer",
    placeholder: "Tel. nummer",
  },
  {
    veld: "adreshuurpand",
    label: "adres huurpand",
    placeholder: "Straat + Nummer, Postcode Gemeente",
  },
  { veld: "huurprijs", label: "huurprijs", placeholder: "Huurprijs" },
  {
    veld: "huurachterstal",
    label: "totaal bedrag huurachterstal",
    placeholder: "Totaal huurachterstal",
  },
  {
    veld: "startdatum",
    label: "startdatum huurovereenkomst",
    placeholder: "DD/MM/JJJJ",
  },
  {
    veld: "einddatum",
    label: "einddatum huurovereenkomst",
    placeholder: "DD/MM/JJJJ",
  },
]

export const verhuurderParticulier = [
  // {
  //   veld: "adres",
  //   label: "adres",
  //   placeholder: "Straat + Nummer, Postcode Gemeente",
  // },
  { veld: "email", label: "e-mail", placeholder: "E-mail adres" },
  {
    veld: "telefoonnummer",
    label: "telefoonnummer",
    placeholder: "Tel. nummer",
  },
]

export const verhuurderVennootschap = [
  {
    veld: "btwnummer",
    label: "btw-nummer",
    placeholder: "BEXXXX.XXX.XXX",
  },
  { veld: "email", label: "e-mail", placeholder: "E-mail adres" },

  {
    veld: "telefoonnummer",
    label: "telefoonnummer",
    placeholder: "Tel. nummer",
  },
]

export const huurder = [
  // {
  //   veld: "adreshuurpand",
  //   label: "adres huurpand",
  //   placeholder: "Straat + Nummer, Postcode Gemeente",
  // },
  { veld: "huurprijs", label: "huurprijs", placeholder: "Huurprijs" },
  {
    veld: "huurachterstal",
    label: "totaal bedrag huurachterstal",
    placeholder: "Totaal huurachterstal",
  },
  {
    veld: "startdatum",
    label: "startdatum huurovereenkomst",
    placeholder: "DD/MM/JJJJ",
  },
  {
    veld: "einddatum",
    label: "einddatum huurovereenkomst",
    placeholder: "DD/MM/JJJJ",
  },
]
