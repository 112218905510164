// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-voorwaarden-js": () => import("./../src/templates/voorwaarden.js" /* webpackChunkName: "component---src-templates-voorwaarden-js" */),
  "component---src-templates-post-js": () => import("./../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-betaling-js": () => import("./../src/pages/betaling.js" /* webpackChunkName: "component---src-pages-betaling-js" */),
  "component---src-pages-blogs-js": () => import("./../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ingebrekestelling-advocaten-js": () => import("./../src/pages/ingebrekestelling-advocaten.js" /* webpackChunkName: "component---src-pages-ingebrekestelling-advocaten-js" */),
  "component---src-pages-persoonlijke-ingebrekestelling-js": () => import("./../src/pages/persoonlijke-ingebrekestelling.js" /* webpackChunkName: "component---src-pages-persoonlijke-ingebrekestelling-js" */),
  "component---src-pages-procedure-js": () => import("./../src/pages/procedure.js" /* webpackChunkName: "component---src-pages-procedure-js" */)
}

