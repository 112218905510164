import React from "react"
import styled from "styled-components"
import Logo from "../../images/logo.png"
import { navigate } from "gatsby"

const LogoDiv = styled.div`
  left: 20px;
  top: 20px;
  &:focus {
    outline: none;
  }
  z-index: 12;
  @media screen and (max-width: 963px) {
    width: 75%;
  }
  @media screen and (max-width: 769px) {
    width: 75%;
  }
`
const StyledH1 = styled.h1`
  font-family: Roboto, sans-serif;

  font-size: 2rem;
  padding: 0;
  color: #6e8a8a;
  margin-top: 0.25rem;
  text-transform: uppercase;
  @media screen and (max-width: 963px) {
    width: 100%;
    font-size: 1.5rem;
    margin-top: 0.75rem;
  }
  @media screen and (max-width: 769px) {
    width: 100%;
    font-size: 1rem;
    margin-top: 0.75rem;
  }
`

export default function Header() {
  const keyHandler = (event, slug) => {
    if (event.key === "Enter") {
      navigate(`/`)
    }
  }
  return (
    <LogoDiv
      className="flex absolute inset-0 ml-2 mt-2 md:ml-4 md:mt-4 mb-12 cursor-pointer"
      onClick={() => navigate("/")}
      onKeyPress={keyHandler}
      role="button"
      tabIndex={0}
    >
      <img
        src={Logo}
        className="w-12 h-12 mr-2 md:mb-4 md:ml-4 "
        alt="Logo Achterstallige huur"
      ></img>
      <StyledH1>Achterstallige Huur</StyledH1>
    </LogoDiv>
  )
}
