import React, { useState } from "react"
import { Formik } from "formik"
import Form from "react-bootstrap/Form"
import Col from "react-bootstrap/Col"
import Spinner from "react-bootstrap/Spinner"

import styled from "styled-components"
import Button from "react-bootstrap/Button"
import { contactSchema } from "./schema"

import { isEmpty } from "../../utils/object"
import axios from "axios"
import Popup from "../Popup/Popup"
export const StyledButton = styled(Button)`
  background-color: #6e8a8a;
  color: #fff;
  border-radius: 1px;
  border-color: #6e8a8a;
  margin-top: 1.5rem;
  font-size: 1rem;
  transition: all 0.2s ease-out;
  display: block;
  &:hover:enabled {
    cursor: pointer;
    transform: scale(1.1);
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);

    background-color: #c1d6d6;
    border-color: #c1d6d6;
    color: #fff;
  }
  &:hover {
    cursor: pointer;
    transform: scale(1.1);
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);

    background-color: #c1d6d6;
    border-color: #c1d6d6;
    color: #fff;
  }
  &:disabled {
    background-color: #d5d5df;
    border-color: #d5d5df;
    cursor: not-allowed;
  }
  &:active {
    cursor: pointer;
    transform: scale(1.1);
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    background-color: #c1d6d6 !important;
    border-color: #c1d6d6 !important;
    color: #fff !important;
  }

  &:focus {
    background-color: #c1d6d6 !important;
    border-color: #c1d6d6 !important;
    color: #fff !important;
    outline: none !important;
  }
`

const submitForm = (values, actions, show, setMsg, type, setLoading) => {
  setLoading(true)
  values.aanvraag = type
  axios
    .post(
      "https://us-central1-acherstalligehuurapi.cloudfunctions.net/AH/email",
      values
    )
    .then(r => {
      actions.resetForm()
      setLoading(false)
      setMsg({
        title: "Succesvol verzonden",
        body:
          "We hebben uw mail goed ontvangen! We beantwoorden die zo snel mogelijk.",
      })
      show()
    })
    .catch(e => {
      setLoading(false)
      setMsg({
        title: "Er ging iets mis",
        body:
          "Gelieve later opnieuw te proberen of te bellen naar 09/269.00.06",
      })
      show()
    })
}

function Contact(props) {
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [message, setMessage] = useState({ title: "", body: "" })
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  return (
    <div className="flex w-10/12 mx-auto flex-column text-center items-center">
      {props.type !== "rechtbank" && (
        <h2 className="text-brand-black m-4 font-heading text-xl md:text-4xl">
          Contacteer ons
        </h2>
      )}
      <div className="w-full text-center items-center">
        <Formik
          validationSchema={contactSchema}
          onSubmit={(values, actions) =>
            submitForm(
              values,
              actions,
              handleShow,
              setMessage,
              props.type,
              setLoading
            )
          }
          initialValues={{
            voornaam: "",
            achternaam: "",
            email: "",
            telefoonnummer: "",
            boodschap: "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
          }) => {
            return (
              <Form
                className=""
                noValidate
                onSubmit={handleSubmit}
                style={{ width: "100%" }}
              >
                <div className="text-left w-full">
                  <Form.Row>
                    <Form.Group as={Col} md="6" controlId="validationFormik01">
                      <Form.Label>
                        Voornaam <span className="text-red-700">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="voornaam"
                        value={values.voornaam}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        isValid={touched.voornaam && !errors.voornaam}
                        isInvalid={touched.voornaam && !!errors.voornaam}
                      />
                      <Form.Control.Feedback>Perfect!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {"Voornaam " + errors.voornaam}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationFormik02">
                      <Form.Label>
                        Achternaam <span className="text-red-700">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="achternaam"
                        value={values.achternaam}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.achternaam && !errors.achternaam}
                        isInvalid={touched.achternaam && !!errors.achternaam}
                      />

                      <Form.Control.Feedback>Perfect!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {"Achternaam " + errors.achternaam}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="6" controlId="validationFormik01">
                      <Form.Label>
                        Email <span className="text-red-700">*</span>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={values.email}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        isValid={touched.email && !errors.email}
                        isInvalid={touched.email && !!errors.email}
                      />
                      <Form.Control.Feedback>Perfect!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {"E-mail " + errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationFormik02">
                      <Form.Label>
                        Telefoonnummer <span className="text-red-700">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="telefoonnummer"
                        value={values.telefoonnummer}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={
                          touched.telefoonnummer && !errors.telefoonnummer
                        }
                        isInvalid={
                          touched.telefoonnummer && !!errors.telefoonnummer
                        }
                      />

                      <Form.Control.Feedback>Perfect!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {"Telefoonnummer " + errors.telefoonnummer}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                  <Form.Group>
                    <Form.Label>
                      Boodschap <span className="text-red-700">*</span>
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      aria-label="Boodschap"
                      type="text"
                      name="boodschap"
                      value={values.boodschap}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      isValid={touched.boodschap && !errors.boodschap}
                      isInvalid={touched.boodschap && !!errors.boodschap}
                    />
                    <Form.Control.Feedback>Perfect!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {"Boodschap " + errors.boodschap}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <StyledButton
                    type="submit"
                    className="w-1/2 mx-auto rounded-sm block"
                    disabled={isEmpty(touched) || !isValid || loading}
                  >
                    {loading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      "Verzend email"
                    )}
                  </StyledButton>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
      <Popup
        show={show}
        handleShow={handleShow}
        handleClose={handleClose}
        message={message}
      />
    </div>
  )
}

export default Contact
