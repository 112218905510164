import React from "react"
import { graphql, useStaticQuery, Link, navigate } from "gatsby"
import Image from "gatsby-image"
import Layout from "../layout"

const Blog = () => {
  const data = useStaticQuery(graphql`
    {
      posts: allContentfulBlog(sort: { fields: creationDate, order: DESC }) {
        nodes {
          slug
          title
          creationDate
          description {
            description
          }
          blogImage {
            fluid(quality: 90, maxWidth: 500, maxHeight: 500) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  `)
  const keyHandler = (event, slug) => {
    if (event.key === "Enter") {
      navigate(`/blog/${slug}`)
    }
  }
  return (
    <Layout>
      <div className="flex flex-col w-full text-center w-10/12 mb-8 mt-12">
        <h2 className="text-brand-black m-8 font-heading text-4xl">
          Nieuws en publicaties
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          {data.posts.nodes.map(post => {
            return (
              <div
                className="mx-auto lg:mx-32 text-left cursor-pointer w-10/12"
                onClick={() => navigate(`/blog/${post.slug}`)}
                onKeyPress={e => keyHandler(e, post.slug)}
                role="button"
                key={post.slug}
                tabIndex={0}
              >
                <Image
                  fluid={post.blogImage.fluid}
                  alt={post.title}
                  className="w-full h-64"
                />
                <h4 className="mt-4 mb-4 text-brand-black font-heading">
                  {post.title}
                </h4>
                <p className="p-0 mb-2 w-full mr-0 text-sm text-brand-dark-gray font-body">
                  {post.description.description}
                </p>
                <Link
                  className="hover:no-underline hover:text-brand-green py-0 text-sm text-brand-dark-gray"
                  to={`/blog/${post.slug}`}
                >
                  Lees verder
                </Link>
              </div>
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

export default Blog
