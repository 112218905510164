import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Image from "gatsby-image"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import styled from "styled-components"
import SEO from "../components/seo"

export const query = graphql`
  query($slug: String!) {
    contentfulBlog(slug: { eq: $slug }) {
      slug
      title
      tags
      body {
        json
      }
      blogImage {
        fluid(quality: 90, maxWidth: 1200, maxHeight: 500) {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`
const BlogDiv = styled.div`
  p {
    font-family: "Lato, serif";
    padding-left: 0;
    padding-right: 2rem;
    color: #363d45;
    @media screen and (max-width: 900px) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
  h1 {
    @media screen and (max-width: 900px) {
      font-size: 1.5rem;
    }
  }
`
const PostTemplate = ({ data: { contentfulBlog: post } }) => (
  <Layout>
    <SEO title={post.title} keywords={post.tags} />
    <div className="flex flex-col mt-32 mx-auto w-full md:w-3/5 items-center">
      <Image
        fluid={post.blogImage.fluid}
        alt={post.title}
        className="h-phoneBlog md:h-blog w-full"
      />
      <h1 className="font-heading text-brand-black mt-4 w-10/12 md:w-full">
        {post.title}
      </h1>

      <BlogDiv className="w-10/12 md:w-full">
        {documentToReactComponents(post.body.json)}
      </BlogDiv>
    </div>
  </Layout>
)

export default PostTemplate
