import React from "react"
import Contact from "../components/forms/Contact"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"

const StyledH2 = styled.h2`
  font-family: "Roboto, sans-serif";

  font-size: 2rem;
  padding: 0;
  color: #141823;
  @media screen and (max-width: 963px) {
    width: 75%;
    margin: 1rem 1rem 11.5rem 1rem;
    font-size: 1.5rem;
  }
  @media screen and (max-width: 769px) {
    font-size: 1.5rem;
    margin: 1rem 1rem 2rem 1rem;
  }
`

const Procedure = () => {
  return (
    <Layout>
      <SEO
        title="Procedure Rechtbank"
        keywords={["Procedure", "Rechtbank", "huurachterstal"]}
      />

      <div className="mt-24 flex flex-column text-center">
        <StyledH2>Procedure voor de rechtbank</StyledH2>
        <p className="font-body text-brand-dark-gray pl-12 pr-12 md:pl-20 md:pr-20 md:ml-24 md:mr-24">
          Indien u wenst om een procedure te starten, laat uw gegevens achter en
          onze experts nemen met jou contact op.
        </p>
        <Contact type="rechtbank" />
      </div>
    </Layout>
  )
}
export default Procedure
