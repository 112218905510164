import React from "react"
import BackgroundImage from "gatsby-background-image"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import { StyledButton } from "./Button/button"

const ImageBackground = styled(BackgroundImage)`
  background-position: 20% 80%;
  background-size: cover;
  min-width: 100%;
  height: 100vh;
  + * {
    margin-top: 0;
  }
`

const BigBox = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  height: 100%;
  align-items: flex-end;
  justify-content: space-around;
  &::first-letter {
    text-transform: capitalize;
  }
  @media screen and (max-width: 963px) {
    align-items: center;
  }
  @media screen and (max-width: 769px) {
    margin-bottom: 8rem;
  }
`

const StyledH2 = styled.h2`
  font-family: Roboto, sans-serif;
  width: 40%;
  margin: 3em 5em 3em 3em;
  font-size: 2rem;
  padding: 0;
  color: #141823;
  @media screen and (max-width: 963px) {
    width: 75%;
    margin: 1rem 1rem 6rem 1rem;
    font-size: 1.5rem;
  }
  @media screen and (max-width: 768px) {
    font-size: 1rem;
  }
  @media screen and (min-width: 769px) and (max-width: 813px) {
    font-size: 1rem;
    margin-top: 8rem;
    padding-left: 2rem;
  }
`

const FlexDiv = styled.div`
  font-family: sans-serif;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
`
const LandingPage = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "landing.png" }) {
        sharp: childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <>
      <FlexDiv>
        <ImageBackground
          aria-label="Achtergrond foto"
          id="fcbg"
          Tag="section"
          fluid={image.sharp.fluid}
          fadeIn="soft"
        >
          <BigBox className="sm:mt-40 sm:ml-32 sm:pl-20 md:mt-24 md:ml-32 md:pl-20 lg:mt-12">
            <StyledH2>
              Welkom bij Achterstallige Huur
              <div className="mb-24 md:mt-4">
                <p className="pl-0 pr-0 text-sm pt-0 text-brand-dark-gray font-body md:mb-4 md:pt-3 md:pr-32 lg:pr-16 md:text-base leading-normal">
                  Huurders die niet betalen? Het kan iedere verhuurder
                  overkomen. Blijf niet bij de pakken zitten en onderneem actie
                  voor het te laat is. Schakel Achterstallige Huur in en wordt
                  gesteund door onze advocaten met een no-nonsens aanpak. Wij
                  helpen u uw huurinkomsten zo snel mogelijk weer te ontvangen.
                  Doeltreffend, gemakkelijk en kostenefficiënt. Op
                  Achterstallige Huur kunt u rekenen.
                </p>
                <StyledButton
                  className="ml-32 sm:ml-40 mt-0 md:ml-0 md:mt-4"
                  href="#werkwijze"
                >
                  Ga van start
                </StyledButton>
              </div>
            </StyledH2>
          </BigBox>
        </ImageBackground>
      </FlexDiv>
    </>
  )
}

export default LandingPage
