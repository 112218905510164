import * as yup from "yup"
export const schema = yup.object({
  dropdown: yup.string().required(),
  huurders: yup.array().of(
    yup.object().shape({
      voornaam: yup.string().required("is een verplicht veld"),
      achternaam: yup.string().required("is een verplicht veld"),
    })
  ),
  verhuurders: yup.array().when("dropdown", {
    is: "Particulier",
    then: yup
      .array(
        yup.object().shape({
          voornaam: yup.string().required("is een verplicht veld"),
          achternaam: yup.string().required("is een verplicht veld"),
        })
      )
      .required(),
    otherwise: yup.array(
      yup.object().shape({
        voornaam: yup.string(),
        achternaam: yup.string(),
      })
    ),
  }),
  naamvennootschap: yup.string().when("dropdown", {
    is: "Vennootschap",
    then: yup.string().required("is een verplicht veld"),
    else: yup.string(),
  }),
  adres: yup.string().required("is een verplicht veld"),

  btwnummer: yup.string().when("dropdown", {
    is: "Vennootschap",
    then: yup.string().required("is een verplicht veld"),
    otherwise: yup.string(),
  }),
  telefoonnummer: yup
    .string()
    .matches(
      /^((\+32|0032)|0)\s?(4(7|8|9)\d{1}|\d{1,2})\/?(((\s?|\.?)\d{2}){3}|((\s?|\.)\d{3}){2})\b/,
      "is ongeldig"
    )
    .required("is een verplicht veld"),
  adreshuurpand: yup.string().required("is een verplicht veld"),
  email: yup
    .string()
    .email("is ongeldig")
    .required("is een verplicht veld"),
  huurprijs: yup
    .string()
    .matches(/^\d*(\.|,){0,1}\d{1,2}$/, "moet een getal zijn")
    .required("is een verplicht veld"),
  huurachterstal: yup
    .string()
    .matches(/^\d*(\.|,){0,1}\d{1,2}$/, "moet een getal zijn")
    .required("is een verplicht veld"),
  startdatum: yup
    .string()
    .matches(
      /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/,
      "moet in het formaat: dd/mm/yyyy"
    )
    .required("is een verplicht veld"),
  einddatum: yup
    .string()
    .matches(
      /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/,
      "moet in het formaat: dd/mm/jjjj"
    )
    .required("is een verplicht veld"),
})

export const contactSchema = yup.object({
  voornaam: yup.string().required("is een verplicht veld"),
  achternaam: yup.string().required("is een verplicht veld"),
  boodschap: yup.string().required("is een verplicht veld"),
  telefoonnummer: yup
    .string()
    .matches(
      /^((\+32|0032)|0)\s?(4(7|8|9)\d{1}|\d{1,2})\/?(((\s?|\.?)\d{2}){3}|((\s?|\.)\d{3}){2})\b/,
      "is ongeldig"
    )
    .required("is een verplicht veld"),

  email: yup
    .string()
    .email("is ongeldig")
    .required("is een verplicht veld"),
})

export const emailSchema = yup.object({
  email: yup
    .string()
    .email("is ongeldig")
    .required("is een verplicht veld"),
})
