import * as yup from "yup"

import HuurderInfo from "./views/HuurderInfo"
import VerhuurderInfo from "./views/VerhuurderInfo"

export default [
  {
    id: "verhuurder",
    component: VerhuurderInfo,
    initialValues: {
      straat: "",
      nummer: "",
      bus: "",
      postcode: "",
      stad: "",
      verhuurders: [{ voornaam: "", achternaam: "" }],
      dropdown: "Particulier",
      email: "",
      btwnummer: "",
      naamvennootschap: "",
      telefoonnummer: "",
    },
    validationSchema: yup.object().shape({
      dropdown: yup.string().required(),
      verhuurders: yup.array().when("dropdown", {
        is: "Particulier",
        then: yup
          .array(
            yup.object().shape({
              voornaam: yup.string().required("is een verplicht veld"),
              achternaam: yup.string().required("is een verplicht veld"),
            })
          )
          .required(),
        otherwise: yup.array(
          yup.object().shape({
            voornaam: yup.string(),
            achternaam: yup.string(),
          })
        ),
      }),
      naamvennootschap: yup.string().when("dropdown", {
        is: "Onderneming",
        then: yup.string().required("is een verplicht veld"),
        otherwise: yup.string(),
      }),
      straat: yup.string().required("is een verplicht veld"),
      nummer: yup.string().required("is een verplicht veld"),
      bus: yup.string(),
      postcode: yup
        .string()
        .matches(/^(?:(?:[1-9])(?:\d{3}))$/, "is ongeldig")
        .required("is een verplicht veld"),
      stad: yup.string().required("is een verplicht veld"),
      btwnummer: yup.string().when("dropdown", {
        is: "Onderneming",
        then: yup
          .string()
          .matches(/^(BE)?0[0-9]{3}\.[0-9]{3}\.[0-9]{3}$/, "is ongeldig")
          .required("is een verplicht veld"),
        otherwise: yup.string(),
      }),
      telefoonnummer: yup
        .string()
        .matches(
          /^((\+32|0032)|0)\s?(4(7|8|9)\d{1}|\d{1,2})\/?(((\s?|\.?)\d{2}){3}|((\s?|\.)\d{3}){2})\b/,
          "is ongeldig"
        )
        .required("is een verplicht veld"),
      email: yup
        .string()
        .email("is ongeldig")
        .required("is een verplicht veld"),
    }),
  },
  {
    id: "huurder",
    component: HuurderInfo,
    initialValues: {
      huurders: [{ voornaam: "", achternaam: "" }],
      straat: "",
      nummer: "",
      dropdownHuurder: "Particulier",
      vennootschapHuurder: "",
      btwnummerHuurder: "",
      bus: "",
      postcode: "",
      stad: "",
      huurprijs: "",
      huurachterstal: "",
      startdatum: "",
      einddatum: "",
      terms: false,
    },
    validationSchema: yup.object().shape({
      dropdownHuurder: yup.string().required(),
      huurders: yup.array().when("dropdownHuurder", {
        is: "Particulier",
        then: yup
          .array(
            yup.object().shape({
              voornaam: yup.string().required("is een verplicht veld"),
              achternaam: yup.string().required("is een verplicht veld"),
            })
          )
          .required(),
        otherwise: yup.array(
          yup.object().shape({
            voornaam: yup.string(),
            achternaam: yup.string(),
          })
        ),
      }),
      vennootschapHuurder: yup.string().when("dropdownHuurder", {
        is: "Onderneming",
        then: yup.string().required("is een verplicht veld"),
        otherwise: yup.string(),
      }),
      btwnummerHuurder: yup.string().when("dropdownHuurder", {
        is: "Onderneming",
        then: yup
          .string()
          .matches(/^(BE)?0[0-9]{3}\.[0-9]{3}\.[0-9]{3}$/, "is ongeldig")
          .required("is een verplicht veld"),
        otherwise: yup.string(),
      }),
      straat: yup.string().required("is een verplicht veld"),
      nummer: yup.string().required("is een verplicht veld"),
      bus: yup.string(),
      postcode: yup
        .string()
        .matches(/^(?:(?:[1-9])(?:\d{3}))$/, "is ongeldig")
        .required("is een verplicht veld"),
      stad: yup.string().required("is een verplicht veld"),

      huurprijs: yup
        .string()
        .matches(/^\d*(\.|,){0,1}\d{1,2}$/, "moet een getal zijn")
        .required("is een verplicht veld"),
      huurachterstal: yup
        .string()
        .matches(/^\d*(\.|,){0,1}\d{1,2}$/, "moet een getal zijn")
        .required("is een verplicht veld"),
      startdatum: yup
        .string()
        .matches(
          /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/,
          "moet in het formaat: dd/mm/yyyy"
        )
        .required("is een verplicht veld"),
      einddatum: yup
        .string()
        .matches(
          /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/,
          "moet in het formaat: dd/mm/jjjj"
        )
        .required("is een verplicht veld"),
      terms: yup
        .bool()
        .oneOf([true], "on")
        .required(),
    }),
    // actionLabel: "Verzend PDF",
  },
]
