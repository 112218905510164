import React, { useState, useEffect } from "react"
import axios from "axios"
import Layout from "../layout"
import SuccesForm from "../Success/SuccesForm"
import ReactLoading from "react-loading"

import ErrorPage from "../Error/Error"

const Betaling = ({ location }) => {
  const [payment, setPayment] = useState(null)
  const [error, setError] = useState(null)
  useEffect(() => {
    const loc = location.pathname.split("/betaling/")[1]
    axios
      .get(
        `https://us-central1-acherstalligehuurapi.cloudfunctions.net/charge/status/${loc}`
      )
      .then(pay => setPayment(pay.data.info.status))
      .catch(err => setError(err))
  }, [location])

  return (
    <Layout>
      <div className="mt-24 flex justify-center items-center">
        {payment === null && error === null ? (
          <ReactLoading type="spin" color="#6e8a8a" height={300} width={150} />
        ) : null}
        {payment === "paid" ? (
          <SuccesForm
            title="Betaling geslaagd"
            text="Bedankt om met Achterstallige Huur samen te werken. Uw betaling is goed ontvangen, dus wij gaan onmiddelijk aan de slag. Indien u nog vragen heeft, kan u steeds contact opnemen via telefoon 09/269.00.06 of via mail info@achterstalligehuur.be
          "
          ></SuccesForm>
        ) : null}
        {payment === "expired" ||
        payment === "canceled" ||
        payment === "failed" ? (
          <ErrorPage
            title={`Betaling niet verwerkt`}
            text="De betaling is niet verwerkt. Indien u wenst kan u ons steeds contacteren op het nummer 09/269.00.06 of via mail op info@achterstalligehuur.be"
          />
        ) : null}

        {error ? (
          <ErrorPage
            title={`Betaling niet gevonden`}
            text="De betaling is niet gevonden. Indien u wenst kan u ons steeds contacteren op het nummer 09/269.00.06 of via mail op info@achterstalligehuur.be"
          />
        ) : null}
      </div>
    </Layout>
  )
}

export default Betaling
