import { Field, useFormikContext } from "formik"
import React from "react"
import {
  verhuurderParticulier,
  verhuurderVennootschap,
} from "../../forms/array"
import Partij from "../../forms/Partij"
import Adres from "../../forms/Adres"
import Input from "../../Input/input"
import Form from "react-bootstrap/Form"

function VerhuurderInfo() {
  const { values, handleChange, errors, touched } = useFormikContext()
  return (
    <>
      <p className="font-body text-brand-dark-gray">
        Vul hier uw persoonlijke informatie in.
      </p>
      <div className="flex flex-col mx-auto form-group col-md-6 text-left">
        <Form.Label className="text-left">
          Identiteit verhuurder <span className="text-red-700">*</span>
        </Form.Label>
        <Form.Control
          className="rounded-none"
          as="select"
          name="dropdown"
          placeholder="Selecteer een optie"
          onChange={handleChange}
          value={values.dropdown}
        >
          <option>Particulier</option>
          <option>Onderneming</option>
        </Form.Control>
      </div>
      {values.dropdown === "Particulier" ? (
        <Partij values={values} partij="verhuurders" />
      ) : (
        <Field
          name="naamvennootschap"
          label="Naam onderneming (inclusief rechtsvorm)"
          placeholder="Naam onderneming"
          component={Input}
        />
      )}

      <Adres
        values={values}
        handleChange={handleChange}
        errors={errors}
        touched={touched}
      ></Adres>

      {values.dropdown === "Particulier"
        ? verhuurderParticulier.map(input => (
            <Field
              key={input.veld}
              name={input.veld}
              label={input.label}
              placeholder={input.placeholder}
              component={Input}
            />
          ))
        : verhuurderVennootschap.map(input => (
            <Field
              key={input.veld}
              name={input.veld}
              label={input.label}
              placeholder={input.placeholder}
              component={Input}
            />
          ))}
    </>
  )
}

export default VerhuurderInfo
