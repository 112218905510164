import React from "react"
import Helmet from "react-helmet"
import "bootstrap/dist/css/bootstrap.min.css"

import "./layout.css"
import { StaticQuery, graphql, Link } from "gatsby"
import styled, { createGlobalStyle } from "styled-components"

import Nav from "./Nav/Nav"
import Header from "./Header/header"

const GlobalStyle = createGlobalStyle`
  html {
    max-width: 100vw;
    overflow-x: hidden;
  }
  a {
    text-decoration: none;
  }
  
 
`

const Body = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  width: 100vw;
  overflow: hidden;
  img {
    margin-bottom: 0;
  }
`

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  width: 100vw;
  margin-top: auto;
  text-align: center;
  color: #fff;
  background-color: #6e8a8a;
  img {
    margin-bottom: 0;
  }
`

const FooterText = styled.div`
  font-size: 0.7em;
  padding: 3em;
`
const StyledLink = styled(Link)`
  &:hover {
    transition: all 0.2s ease-out;
    transform: scale(1.1);
    text-decoration: none;
    color: #fff;
  }
`

const links = [
  { slug: "/algemene-voorwaarden", title: "Algemene Voorwaarden" },
  { slug: "/disclaimer", title: "Disclaimer" },
  { slug: "/privacyverklaring", title: "Privacyverklaring" },
]

const Layout = ({ children, noMenu }) => (
  <StaticQuery
    query={graphql`
      query layoutQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Body>
          <GlobalStyle />
          <Helmet title={data.site.siteMetadata.title}>
            <html lang="nl" />
            <meta name="description" content="Achterstallige huur" />
          </Helmet>
          <Header />
          <Nav />
          {children}
          <Footer>
            <FooterText className="font-body">
              <div className="pb-1">Achterstalligehuur.be &copy; 2020</div>
              <div className="pb-1">
                Email:{" "}
                <a href="mailto:info@achterstalligehuur.be">
                  info@achterstalligehuur.be
                </a>
              </div>
              <div className="pb-1">
                Telefoonnummer: <a href="tel:+3292690006">09/269.00.06</a>
              </div>
              <ul className="flex flex-col m-0">
                {links.map(link => (
                  <StyledLink key={link.title} to={link.slug}>
                    {link.title}
                  </StyledLink>
                ))}
              </ul>
            </FooterText>
          </Footer>
        </Body>
      </>
    )}
  />
)

export default Layout
