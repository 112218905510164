import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import styled from "styled-components"

export const query = graphql`
  query($slug: String!) {
    contentfulAlgemeneVoorwaarden(slug: { eq: $slug }) {
      slug
      title
      voorwaardenTekst {
        json
      }
    }
  }
`
const BlogDiv = styled.div`
  margin-top: 6rem;
  p {
    font-family: "Lato, serif";
    padding-left: 2rem;
    padding-right: 2rem;
    color: #363d45;
    @media screen and (max-width: 900px) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
  h1 {
    @media screen and (max-width: 900px) {
      font-size: 1.5rem;
    }
  }
`
const VoorwaardeTemplate = ({
  data: { contentfulAlgemeneVoorwaarden: voorwaarde },
}) => (
  <Layout>
    <BlogDiv className="w-10/12 md:w-full">
      <h3 className="font-heading text-brand-black ml-4 w-10/12 md:w-full">
        {voorwaarde.title}
      </h3>
      {documentToReactComponents(voorwaarde.voorwaardenTekst.json)}
    </BlogDiv>
  </Layout>
)

export default VoorwaardeTemplate
