import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import styled from "styled-components"
import Navbar from "react-bootstrap/Navbar"
import Overlay from "../Overlay/overlay"

const StyledNav = styled(Navbar)`
  /* background: rgba(255, 255, 255, 0.9); */
  position: fixed;
  display: flex;
  justify-content: flex-end;
  .hamburger {
    width: 35px;
    height: 5px;
    background-color: #6e8a8a;
    margin: 3px 0;
  }
  z-index: 16;
  top: 20px;
  right: 20px;
  svg {
    color: #6e8a8a;
  }
`

export default function Nav() {
  const [isActive, setActive] = useState(false)
  const handleOverlay = boolean => setActive(boolean)
  return (
    <>
      <StyledNav>
        {isActive ? (
          <FontAwesomeIcon
            size="2x"
            style={{ color: "#eeedf2", cursor: "pointer", width: "50px" }}
            onClick={() => handleOverlay(false)}
            icon={faTimes}
            role="button"
            aria-label="Sluit Menu"
          ></FontAwesomeIcon>
        ) : (
          <button
            className="flex flex-col pointer"
            aria-label="Open Menu"
            onClick={() => handleOverlay(true)}
          >
            <div className="hamburger"></div>
            <div className="hamburger"></div>
            <div className="hamburger"></div>
          </button>
        )}
      </StyledNav>
      <Overlay isActive={isActive} handleOverlay={handleOverlay} />
    </>
  )
}
