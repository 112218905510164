import React from "react"
import { Field, Formik } from "formik"
import Form from "react-bootstrap/Form"
import Input from "../Input/input"
import styled from "styled-components"
import Button from "react-bootstrap/Button"
import { emailSchema } from "./schema"
import axios from "axios"

import Spinner from "react-bootstrap/Spinner"
export const StyledButton = styled(Button)`
  background-color: #6e8a8a;
  color: #fff;
  border-radius: 1px;
  border-color: #6e8a8a;
  margin-top: 1.5rem;
  font-size: 1rem;
  transition: all 0.2s ease-out;
  display: block;
  &:hover:enabled {
    cursor: pointer;
    transform: scale(1.1);
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);

    background-color: #c1d6d6;
    border-color: #c1d6d6;
    color: #fff;
  }
  &:hover {
    cursor: pointer;
    transform: scale(1.1);
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);

    background-color: #c1d6d6;
    border-color: #c1d6d6;
    color: #fff;
  }
  &:active {
    cursor: pointer;
    transform: scale(1.1);
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    background-color: #c1d6d6 !important;
    border-color: #c1d6d6 !important;
    color: #fff !important;
  }
  &:disabled {
    background-color: #d5d5df;
    border-color: #d5d5df;
    cursor: not-allowed;
  }
  &:focus {
    outline: none !important;
  }
`

function Email(props) {
  const payment = values => {
    axios
      .post(
        "https://us-central1-acherstalligehuurapi.cloudfunctions.net/charge",
        values
      )
      .then(pay =>
        window.location.replace(pay.data.payment._links.checkout.href)
      )
      .catch(err => console.log(err))
  }
  return (
    <Formik
      validationSchema={emailSchema}
      onSubmit={payment}
      initialValues={{
        email: "",
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        isSubmitting,
      }) => {
        return (
          <Form
            className=""
            noValidate
            onClick={handleSubmit}
            style={{ marginLeft: "3rem", marginRight: "3rem" }}
          >
            <Field
              name="email"
              label="Uw e-mailadres"
              placeholder="E-mail"
              component={Input}
            />
            <div className="flex flex-col md:flex-row items-center justify-center">
              <div className="w-1/2 flex flex-col md:flex-row justify-center md:justify-between">
                <StyledButton
                  type="button"
                  className="w-full md:w-1/3 rounded-sm md:mx-4"
                  onClick={() => {
                    props.setShowEmail(false)
                    props.setShowButtons(true)
                  }}
                >
                  Keer terug
                </StyledButton>
                <StyledButton
                  type="submit"
                  className="w-full md:w-1/3 md:mx-4 rounded-sm"
                  disabled={!isValid || isSubmitting}
                >
                  {isSubmitting ? (
                    <>
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />{" "}
                      Betalen
                    </>
                  ) : (
                    "Betalen"
                  )}
                </StyledButton>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default Email
