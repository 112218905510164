import React from "react"
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"

const Popup = ({ show, handleClose, handleShow, message }) => {
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="font-heading text-brand-black">
            {message.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="font-body text-brand-dark-gray">
          {message.body}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="border-brand-green bg-brand-green text-white hover:bg-brand-light-green hover:border-brand-light-green"
            onClick={handleClose}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Popup
