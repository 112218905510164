import { Field, useFormikContext } from "formik"
import React from "react"
import Partij from "../../forms/Partij"
import Adres from "../../forms/Adres"
import Input from "../../Input/input"
import { Link } from "gatsby"
import Form from "react-bootstrap/Form"
import { huurder } from "../../forms/array"

function HuurderInfo() {
  const { values, handleChange, errors, touched } = useFormikContext()

  return (
    <>
      <p className="font-body text-brand-dark-gray">
        Vul hier uw huurders informatie in.
      </p>
      <div className="flex flex-col mx-auto form-group col-md-6 text-left">
        <Form.Label className="text-left">
          Identiteit huurder <span className="text-red-700">*</span>
        </Form.Label>
        <Form.Control
          className="rounded-none"
          as="select"
          name="dropdownHuurder"
          placeholder="Selecteer een optie"
          onChange={handleChange}
          value={values.dropdown}
        >
          <option>Particulier</option>
          <option>Onderneming</option>
        </Form.Control>
      </div>
      {values.dropdownHuurder === "Particulier" ? (
        <Partij values={values} partij="huurders" />
      ) : (
        <Field
          name="vennootschapHuurder"
          label="Naam onderneming (inclusief rechtsvorm)"
          placeholder="Naam onderneming"
          component={Input}
        />
      )}
      <Adres
        values={values}
        handleChange={handleChange}
        errors={errors}
        touched={touched}
      ></Adres>
      {values.dropdownHuurder === "Onderneming" ? (
        <Field
          name="btwnummerHuurder"
          label="Btw-nummer huurder"
          placeholder="BEXXXX.XXX.XXX"
          component={Input}
        />
      ) : null}
      {huurder.map(input => (
        <Field
          key={input.veld}
          name={input.veld}
          label={input.label}
          placeholder={input.placeholder}
          component={Input}
        />
      ))}
      <div className="flex flex-col mx-auto form-group col-md-6 text-left">
        <Form.Check isInvalid={!!errors.terms} type="checkbox" id="check">
          <Form.Check.Input
            onChange={handleChange}
            name="terms"
            type="checkbox"
          />
          <Form.Check.Label className="text-body text-sm">
            Ik heb de{" "}
            <a
              href="https://www.achterstalligehuur.be/algemene-voorwaarden"
              target="_blank"
              rel="noopener noreferrer"
            >
              algemene voorwaarden
            </a>{" "}
            en{" "}
            <a
              href="https://www.achterstalligehuur.be/privacyverklaring"
              target="_blank"
              rel="noopener noreferrer"
            >
              privacyvoorwaarden
            </a>{" "}
            van Achterstallige Huur gelezen en ga hiermee akkoord.
          </Form.Check.Label>
          <Form.Control.Feedback type="valid">Perfect!</Form.Control.Feedback>
        </Form.Check>
      </div>
    </>
  )
}

export default HuurderInfo
