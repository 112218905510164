import React from "react"

import Layout from "../components/layout"

import SEO from "../components/seo"
import Landingpage from "../components/landingPage"
import PriceCards from "../components/PriceCards/priceCards"
import WhyUs from "../components/WhyUs/WhyUs"
import BlogPreview from "../components/BlogPreview/BlogPreview"
import Review from "../components/Review/Review"
import Contact from "../components/forms/Contact"
import styled from "styled-components"
import Team from "../components/Team/Team"

const StyledSection = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

//form validation use Yup
//react bootstrap for styling or styled components
const IndexPage = () => (
  <Layout>
    <SEO
      title="Achterstallige huur"
      keywords={[`huurachterstal`, `verhuurders`, `huurproblemen`]}
    />
    <div className="flex flex-column">
      <StyledSection>
        <Landingpage />
      </StyledSection>

      <StyledSection id="werkwijze" className="mt-12">
        <PriceCards />
      </StyledSection>

      <StyledSection className="mt-12" id="waarom-wij">
        <WhyUs />
      </StyledSection>

      <StyledSection className="mt-12" id="team">
        <Team />
      </StyledSection>

      <StyledSection className="mt-12">
        <Review />
      </StyledSection>
      <StyledSection className="mt-12">
        <BlogPreview />
      </StyledSection>
      <StyledSection className="mt-12" id="contact">
        <Contact />
      </StyledSection>
    </div>
  </Layout>
)

export default IndexPage
