import FormikWizard from "formik-wizard"
import React from "react"
import { StyledButton } from "../Button/button"
import steps from "./steps"
import axios from "axios"
import { useFormikContext } from "formik"

import Spinner from "react-bootstrap/Spinner"
import { useState } from "react"
import Error from "../Error/Error"

function FormWrapper({
  children,
  isLastStep,
  status,
  goToPreviousStep,
  canGoBack,
  actionLabel,
}) {
  const { isValid, isSubmitting } = useFormikContext()

  return (
    <div className="mt-24 text-center">
      {status ? (
        <div>
          {status.message}
          <hr />
        </div>
      ) : (
        <>
          {children}
          <div className="flex md:block">
            <StyledButton
              type="button"
              onClick={goToPreviousStep}
              hidden={!canGoBack}
              className="w-1/3 md:w-1/5 mx-4 rounded-sm"
            >
              Vorige
            </StyledButton>

            <StyledButton
              type="submit"
              className="w-1/3 md:w-1/5 mx-4 rounded-sm"
              disabled={isSubmitting || !isValid}
            >
              {isSubmitting ? (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{" "}
                  Betalen
                </>
              ) : (
                actionLabel || (isLastStep ? "Betalen" : "Volgende")
              )}
            </StyledButton>
          </div>
        </>
      )}
    </div>
  )
}

function MultiFormAdvocaat() {
  const payment = (values, setError) => {
    return new Promise((_, reject) => {
      const pdfadvocaat = axios.post(
        "https://us-central1-acherstalligehuurapi.cloudfunctions.net/AH/pdfadvocaat",
        values
      )
      const payment = axios.post(
        "https://us-central1-acherstalligehuurapi.cloudfunctions.net/charge",
        {
          email: values.verhuurder.email,
        }
      )

      axios
        .all([pdfadvocaat, payment])
        .then(
          axios.spread(function(pdfadvocaat, payment) {
            window.location.replace(payment.data.payment._links.checkout.href)
          })
        )
        .catch(err => {
          setError(true)
          return reject({ message: "Er ging iets mis." })
        })
    })
  }
  const [error, setError] = useState(false)
  const text =
    "De betaling is niet doorgegaan. Gelieve opnieuw te proberen of ons te contacteren op 09/269.00.06"
  return (
    <>
      {error ? (
        <Error title="Er ging iets mis" text={text} />
      ) : (
        <FormikWizard
          steps={steps}
          onSubmit={values => payment(values, setError)}
          render={FormWrapper}
        />
      )}
    </>
  )
}

export default MultiFormAdvocaat
