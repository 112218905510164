import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO
      title="404: Not found"
      keywords={["404", "Niet gevonden", "Not Found"]}
    />
    <div className="mt-32">
      <h1>404: Niet gevonden</h1>
      <p>Deze webpagina is niet gevonden </p>
    </div>
  </Layout>
)

export default NotFoundPage
