import FormikWizard from "formik-wizard"
import React from "react"
import { StyledButton } from "../Button/button"
import steps from "./steps"
import axios from "axios"
import { useFormikContext } from "formik"

import Spinner from "react-bootstrap/Spinner"
import { useState } from "react"
import Success from "../Success/SuccesForm"
import Error from "../Error/Error"

function FormWrapper({
  children,
  isLastStep,
  status,
  goToPreviousStep,
  canGoBack,
  actionLabel,
}) {
  const { isValid, isSubmitting } = useFormikContext()

  return (
    <div className="mt-24 text-center">
      {status ? (
        <div>
          {status.message}
          <hr />
        </div>
      ) : (
        <>
          {children}
          <div className="flex md:block">
            <StyledButton
              type="button"
              onClick={goToPreviousStep}
              hidden={!canGoBack}
              className="w-1/3 md:w-1/5 mx-4 rounded-sm"
            >
              Vorige
            </StyledButton>

            <StyledButton
              type="submit"
              className="w-1/3 md:w-1/5 mx-4 rounded-sm"
              disabled={isSubmitting || !isValid}
            >
              {isSubmitting ? (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{" "}
                  PDF aanmaken
                </>
              ) : (
                actionLabel || (isLastStep ? "Verzend PDF" : "Volgende")
              )}
            </StyledButton>
          </div>
        </>
      )}
    </div>
  )
}

function MultiForm() {
  const handleSubmit = (values, setSuccess, setError) => {
    return new Promise((resolve, reject) => {
      const pdfadvocaat = axios.post(
        "https://us-central1-acherstalligehuurapi.cloudfunctions.net/AH/pdfadvocaat",
        values
      )
      const pdf1 = axios.post(
        "https://us-central1-acherstalligehuurapi.cloudfunctions.net/AH/pdf1",
        values
      )

      axios
        .all([pdfadvocaat, pdf1])
        .then(
          axios.spread(function(pdfadvocaat, pdf1) {
            setSuccess(true)
            return resolve({ message: "Bedankt!" })
          })
        )
        .catch(err => {
          setError(true)
          return reject({ message: "Er ging iets mis." })
        })
    })
  }
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const text =
    "Het aanmaken van de pdf is mislukt. Gelieve opnieuw te proberen of ons te contacteren op 09/269.00.06"
  const succesText = `Bedankt om met Achterstallige Huur samen te werken. U ontvangt meteen
    uw persoonlijke ingebrekestelling. Heeft dit niet het gewenste effect?
    Neem dan zo snel mogelijk terug contact met ons op. Onze
    contactgegevens vindt u op de website en in de e-mail met de
    persoonlijke ingebrekestelling.`
  return (
    <>
      {error ? (
        <Error title="Er ging iets mis" text={text} />
      ) : success ? (
        <Success title="Op naar resultaat" text={succesText} />
      ) : (
        <FormikWizard
          steps={steps}
          onSubmit={values => handleSubmit(values, setSuccess, setError)}
          render={FormWrapper}
        />
      )}
    </>
  )
}

export default MultiForm
