import React from "react"
import WhyUsCard from "../WhyUsCard/WhyUsCard"
import heart from "../../images/house.svg"
import runMan from "../../images/running-man.svg"
import money from "../../images/money-bag.svg"
import custService from "../../images/customer-service.svg"

const redens = [
  {
    image: runMan,
    title: "Snel & eenvoudig",
    description: `U vult uw gegevens online in, Achterstallige Huur gaat onmiddellijk aan de slag. Binnen 48 uur wordt een aangetekende zending verstuurd.`,
  },

  {
    image: money,
    title: "Bewaak uw inkomsten",
    description: `U loopt inkomsten mis door huurders die niet betalen. Bespaar uzelf de kopzorgen en vertrouw op Achterstallige Huur om niet langer nog inkomsten te verliezen.`,
  },
  {
    image: custService,
    title: "Uw persoonlijke expert",
    description: `Eén ervaren advocaat wordt aan uw dossier toegewezen. Deze is telefonisch en per e-mail steeds bereikbaar. Reken op een vast en transparant tarief, zo weet u altijd waar u aan toe bent.`,
  },
  {
    image: heart,
    title: "Een passie voor uw vastgoed!",
    description:
      "Onze advocaten zijn gepassioneerd door vastgoed en gespecialiseerd in vastgoedrecht. Zij stellen hun expertise voor u ter beschikking",
  },
]
export default function WhyUs() {
  return (
    <div className="flex flex-column text-center">
      <h2 className="text-brand-black m-4 font-heading text-xl md:text-4xl px-12 md:px-0">
        Waarom Achterstallige Huur?
      </h2>
      <div className="flex flex-wrap flex-col md:flex-row md:pl-12 md:pr-12">
        {redens.map(reden => (
          <WhyUsCard
            key={reden.title}
            image={reden.image}
            title={reden.title}
            description={reden.description}
          />
        ))}
      </div>
    </div>
  )
}
