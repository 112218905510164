import React from "react"
import { graphql, useStaticQuery, Link, navigate } from "gatsby"
import Image from "gatsby-image"

const BlogPreview = () => {
  const data = useStaticQuery(graphql`
    {
      posts: allContentfulBlog(
        limit: 3
        sort: { fields: creationDate, order: DESC }
      ) {
        nodes {
          slug
          title
          creationDate
          description {
            description
          }
          blogImage {
            fluid(quality: 90, maxWidth: 500, maxHeight: 500) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  `)
  const keyHandler = (event, slug) => {
    if (event.key === "Enter") {
      navigate(`/blog/${slug}`)
    }
  }
  return (
    <div className="flex flex-col  text-center w-10/12 mb-8">
      <h2 className="text-brand-black m-4 font-heading text-xl md:text-4xl">
        Nieuws en publicaties
      </h2>
      <div className="flex flex-col md:flex-row mt-4 justify-center">
        {data.posts.nodes.map(post => {
          return (
            <div
              className="mx-auto mb-4 lg:mx-32 text-left w-10/12 md:w-2/7 cursor-pointer transition-all duration-500 ease-in transform hover:scale-105"
              onClick={() => navigate(`/blog/${post.slug}`)}
              onKeyPress={e => keyHandler(e, post.slug)}
              role="button"
              key={post.slug}
              tabIndex={0}
            >
              <Image
                fluid={post.blogImage.fluid}
                alt={post.title}
                className="w-full h-48"
              />
              <h4 className="mt-4 mb-2 text-brand-black font-heading">
                {post.title}
              </h4>
              <p className="p-0 mb-2 w-full mr-0 text-sm text-brand-dark-gray font-body">
                {post.description.description}
              </p>
              <Link
                className="hover:no-underline hover:text-brand-green py-0 text-sm text-brand-dark-gray"
                to={`/blog/${post.slug}`}
              >
                Lees verder
              </Link>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default BlogPreview
