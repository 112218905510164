import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Form from "../components/MultiForm/MultiForm"

const PersIngebrekestelling = () => (
  <Layout>
    <SEO
      title="Formulier persoonlijke ingebrekestelling"
      keywords={["Persoonlijke Ingebrekestelling"]}
    />

    <Form />
  </Layout>
)

export default PersIngebrekestelling
