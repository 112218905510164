import React from "react"

export default function WhyUsCard({ image, title, description }) {
  return (
    <div className="w-3/4 md:w-1/2 lg:w-1/4 text-center my-8 lg:my-0 lg:mt-8 mx-auto">
      <img
        src={image}
        alt="Hart icoontje"
        className="w-1/2 md:w-1/3 mx-auto mt-4 mb-2"
      ></img>
      <p className="px-6 inline text-brand-green font-semibold font-heading">
        {title}
      </p>
      <p className="px-8  mt-4 text-brand-dark-gray text-sm font-body text-left">
        {description}
      </p>
    </div>
  )
}
