import React from "react"
import styled from "styled-components"

const StyledH2 = styled.h2`
  font-family: "Roboto, sans-serif";
  width: 40%;

  font-size: 2rem;
  padding: 0;
  color: #141823;
  @media screen and (max-width: 963px) {
    width: 75%;
    margin: 1rem 1rem 11.5rem 1rem;
    font-size: 1.5rem;
  }
  @media screen and (max-width: 769px) {
    font-size: 1.5rem;
    margin: 1rem 1rem 2rem 1rem;
  }
`
export default function PreviousEncounter(props) {
  return (
    <div className="flex flex-col items-center mt-24">
      <StyledH2>Uw gegevens al reeds achtergelaten?</StyledH2>
      <p className="font-body text-brand-dark-gray pl-12 pr-12 md:pl-20 md:pr-20 md:ml-24 md:mr-24">
        Indien u uw gegevens al reeds hebt achtergelaten, volstaat het om enkel
        uw e-mailadres in te vullen. Zoniet, moet u het formulier nog invullen.
        Na ontvangst van betaling gaan wij onmiddellijk van start om uw
        ingebrekestelling aangetekend op te sturen.
      </p>

      {props.showButtons ? (
        <div className="flex flex-col md:flex-row mb-24 mt-12 font-body">
          <button
            type="button"
            onClick={() => {
              props.handleShowEmail()
              props.setShowButtons(false)
            }}
            className="border-2 px-0 my-2 px-4 py-2 mx-4 border-solid border-brand-green font-body text-brand-dark-gray shadow-sm hover:bg-brand-green hover:text-white"
          >
            Ik heb mijn gegevens al eens ingevuld
          </button>
          <button
            type="button"
            onClick={() => {
              props.handleShowForm()
              props.setShowButtons(false)
            }}
            className="border-2 px-4 my-2 py-2 mx-4 border-solid border-brand-green font-body text-brand-dark-gray shadow-sm hover:bg-brand-green hover:text-white"
          >
            Ik moet mijn gegevens nog invullen
          </button>
        </div>
      ) : null}
    </div>
  )
}
