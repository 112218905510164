import React from "react"
import { Router } from "@reach/router"
import Betaling from "./Betaling"

const Payment = () => {
  return (
    <Router basepath="/betaling">
      <Betaling path="/:id"></Betaling>
    </Router>
  )
}
export default Payment
