import React from "react"
import letter from "../../images/letter.svg"
import avatar from "../../images/avatar.svg"
import house from "../../images/litigation.svg"
import Card from "../Card/card"

const cards = [
  {
    id: 1,
    title: "Persoonlijke ingebrekestelling",
    image: letter,
    description: `Uw opbrengsteigendom moet renderen.
      In deze filosofie biedt Achterstallige Huur
      een gratis gepersonaliseerde ingebrekestelling
      aan die u rechtstreeks naar uw huurder kan versturen. Laat uw gegevens na en ontvang uw brief per e-mail.`,
    price: "Gratis",
    slug: "/persoonlijke-ingebrekestelling",
  },
  {
    id: 2,
    title: "Brief door onze advocaten",
    image: avatar,
    description: `Mocht de gepersonaliseerde ingebrekestelling
      niet baten of u heeft uw huurder reeds veelvuldig
      aangeschreven laten wij onze advocaten binnen de
      48 uur na het indienen van uw dossier uw huurder
      aangetekend in gebreke stellen.`,
    price: "€40 exclusief BTW",
    slug: "/ingebrekestelling-advocaten",
  },
  {
    id: 3,
    title: "Procedure voor de rechtbank",
    image: house,
    description: `Indien een minnelijke akkoord niet mogelijk blijkt,
      starten wij een procedure voor het Vredegerecht
      om de onbetaalde huur in te vorderen en
      het huurcontract te laten ontbinden.
      Onze advocaten verdedigen uw belangen ter zitting.
      `,
    price: "€600 exclusief BTW",
    slug: "/procedure",
  },
]
export default function PriceCards() {
  return (
    <div className="flex flex-column text-center">
      <h2 className="text-brand-black m-4 font-heading text-xl md:text-4xl">
        Onze werkwijze
      </h2>
      <div className="text-center lg:flex max-w-full">
        {cards.map(card => (
          <Card
            id={card.id}
            key={card.id}
            image={card.image}
            title={card.title}
            description={card.description}
            price={card.price}
            slug={card.slug}
          />
        ))}
      </div>
    </div>
  )
}
